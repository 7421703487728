<script setup>
const {isOpen, closeIcon, width} = defineProps({
  isOpen: {
    type: Boolean,
    required: true
  },
  closeIcon: {
    type: Boolean,
    default: false
  },
  width: {
    type: Number
  }
})
const emit = defineEmits(['close', 'confirm'])
</script>
<template>
  <div v-if="isOpen" class="z-100 fixed inset-0 flex items-center justify-center bg-darkBlue bg-opacity-50">
    <div class="bg-white p-4 rounded-[20px] shadow-lg min-w-[100px]" :style="{ width: width ? `${width}px` : '' }" :class="{ 'w-1/2 xl:w-1/3': !width }">
      <div v-if="closeIcon">
        <slot name="closeIcon">
          <div class="relative w-full">
            <IconCloseButton class="size-6 absolute right-0 cursor-pointer" @click="emit('close')" />
          </div>
        </slot>
      </div>
      <div class="text-center text-2xlg lg:text-30">
        <slot name="header">
        </slot>
      </div>
      <div>
        <slot name="content">
        </slot>
      </div>
      <div>
        <slot name="footer">
          <div class="flex justify-end mt-4">
            <button class="px-4 h-10 text-darkBlue border border-darkBlue rounded-lg" @click="emit('close')">
              {{ $t('cancel') }}
            </button>
            <button class="ml-2 px-4 h-10 bg-darkBlue text-white rounded-lg transition-all duration-50 active:scale-95" @click="emit('confirm')">
              {{ $t('confirm') }}
            </button>
          </div>
        </slot>
      </div>
    </div>
  </div>
</template>